import React, { createContext, useState, useEffect } from "react";

import ApiConfig from "src/config/api-config";

import axios from "axios";
import { calculateTimeLeft } from "src/utils/index";
import { useHistory } from "react-router-dom";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("indicDigitalAdmin", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("indicDigitalAdmin");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("indicDigitalAdmin");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin);
  const [timeLeft, setTimeLeft] = useState();
  const [endTime, setEndtime] = useState();
  const [userData, setUserData] = useState({});
  const [dashboardData, setDashboardData] = useState([]);
  const history = useHistory();
  const dashBoardDataHandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.dashboard,
        headers: {
          token: token ? token : sessionStorage.getItem("indicDigitalAdmin"),
        },
      });
      if (res.data.responseCode === 200) {
        setDashboardData(res.data.result);
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    } catch (error) {}
  };
  const getProfileHandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.profile,
        headers: {
          token: token ? token : sessionStorage.getItem("indicDigitalAdmin"),
        },
      });
      if (res.data.responseCode === 200) {
        setUserData(res.data.result);
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    } catch (error) {
      if (error?.response?.data?.responseCode === 404 ||  error?.response?.data?.responseCode === 401) {
        sessionStorage.removeItem("indicDigitalAdmin");
        window.location.reload();
        history.push("/");
      }
      
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("indicDigitalAdmin")) {
      dashBoardDataHandler(sessionStorage.getItem("indicDigitalAdmin"));
      getProfileHandler(sessionStorage.getItem("indicDigitalAdmin"));
    }
  }, [sessionStorage.getItem("indicDigitalAdmin")]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    timeLeft,
    endTime,
    setEndtime,
    dashboardData,
    getProfileHandler,
    dashBoardDataHandler: (item) => dashBoardDataHandler(item),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
