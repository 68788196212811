import React, { createContext, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { SUPPORTED_WALLETS } from "src/connectors";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";

export const UserContext = createContext();

export default function AuthProvider(props) {
  const [isOpenConnectWallet, setIsOpenConnectWallet] = useState(false);
  const { activate, account, chainId, deactivate } = useWeb3React();

  const connectWalletHandler = (data) => {
    try {
      const connector = data?.connector;
      localStorage.setItem("walletName", data?.name);
      sessionStorage.removeItem("walletName");
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("walletName");
          // activate(connector);
          console.log("error", error);
        }
      });
    } catch (error) {
      // toast.error(JSON.stringify(error.message));
    }
  };
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  //-----*WEB3-Network-Add-Request*-----//
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      // toast.warn(error.message);
    }
  };

  let data = {
    isOpenConnectWallet,
    setIsOpenConnectWallet: (item) => setIsOpenConnectWallet(item),

    connectWallet: (item) => connectWalletHandler(item),
  };

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter((data) => {
        return data?.data?.name == localStorage.getItem("walletName");
      });
      if (selectectWalletDetails.length > 0) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, [localStorage.getItem("walletName")]); //eslint-disable-line

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler("hey");
        }
      }
    }
  }, [chainId, account]);

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
