import { InjectedConnector } from "@web3-react/injected-connector";
import { RPC_URL } from "src/constants";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
export const injected = new InjectedConnector({
  supportedChainIds: [
    1, 3, 4, 5, 42, 56, 97, 100, 1001, 9731, 9732, 43114, 43113, 1285, 80001,
    137,1337
  ],
});

export const walletconnect = new WalletConnectConnector({
  rpc: {
    137: RPC_URL,
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 15000,
});
export const SUPPORTED_WALLETS = [
  {
    name: "METAMASK",
    data: {
      connector: injected,
      name: "MetaMask",
      iconName: "images/metamask.png",
      description: "Easy-to-use browser extension.",
      href: null,
      color: "#E8831D",
    },
  },
];
