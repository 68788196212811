// let url = "http://172.16.1.226:2056";
let url = "https://node.gyanbatua.com";



// const url =  "https://nodev3-learningrecords.mobiloitte.io";
const challenges = "https://python.indicchain.com"; 

const resume = "https://python.indicchain.com"; 

const coePartner = `${url}/api/v1/coePartners`;
const Blogs = `${url}/api/v1/blog`;
const Jobs = `${url}/api/v1/jobs`;
const media = `${url}/api/v1/media`;
const membership = `${url}/api/v1/membership`;

const ApiConfig = {
  subscriptionHistoryData: `${url}/api/v1/payment/subscriptionHistoryData`,
  subcriptionDatalist: `${url}/api/v1/payment/subcriptionDatalist`,
  // dropCourseSubcriptionData: `${url}/api/v1/payment/dropCourseSubcriptionData`,

  

  fetchTrackAddressDetails: `${url}/api/v1/payment/fetchTransactionDetails`,
  fetchUniqueAddresses: `${url}/api/v1/payment/fetchUniqueAddresses`,
  getWalletDetails: `${url}/api/v1/payment/getWalletDetails`,
  getGCBalance: `${url}/api/v1/payment/getGCBalance`,

  createPlan: `${membership}/createPlan`,
  viewPlan: `${membership}/viewPlan`,
  editPlan: `${membership}/editPlan`,
  deleteplan: `${membership}/deleteplan`,
  listplan: `${membership}/listplan`,
  addBlog: `${Blogs}/addBlog`,
  blogList: `${Blogs}/blogList`,
  viewBlog: `${Blogs}/viewBlog`,
  editBlog: `${Blogs}/editBlog`,
  deleteBlog: `${Blogs}/deleteBlog`,

  addJobs: `${Jobs}/addJobs`,
  jobsList: `${Jobs}/jobsList`,
  viewJobs: `${Jobs}/viewJobs`,
  editJobs: `${Jobs}/editJobs`,
  deleteJobs: `${Jobs}/deleteJobs`,

  addCareer: `${Jobs}/addCareer`,
  listCareer: `${Jobs}/listCareer`,
  viewCareer: `${Jobs}/viewCareer`,
  editCareer: `${Jobs}/editCareer`,
  deleteCareer: `${Jobs}/deleteCareer`,

  addMedia: `${media}/addMedia`,
  listMedia: `${media}/listMedia`,
  viewMedia: `${media}/viewMedia`,
  editMedia: `${media}/editMedia`,
  deleteMedia: `${media}/deleteMedia`,
  listAllMedia: `${media}/listAllMedia`,

  addCoePartner: `${coePartner}/addCoePartner`,
  coePartnerList: `${coePartner}/coePartnerList`,
  viewCoePartner: `${coePartner}/viewCoePartner`,
  editCoePartner: `${coePartner}/editCoePartner`,
  deleteCoePartner: `${coePartner}/deleteCoePartner`,

  userListByCourse: `${url}/api/v1/admin/userListByCourse`,
  userListByDropCourse: `${url}/api/v1/admin/userListByDropCourse`,
  getAllCoursesBySubCategory: `${url}/api/v1/course/getAllCoursesBySubCategory`,

  findAllSubscribe: `${url}/api/v1/user/findAllSubscribe`,

  uploadImage: `${url}/api/v1/user/uploadImage`,
  logIn: `${url}/api/v1/admin/logIn`,

  otpVerify: `${url}/api/v1/admin/otpVerify`,
  uplaodimage: `${url}/api/v1/user/uplaodimage`,

  profile: `${url}/api/v1/admin/profile`,
  signUp: `${url}/api/v1/admin/signUp`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  uploadImage: `${url}/api/v1/user/uploadImage`,
  searchDataUproval: `${url}/api/v1/admin/searchDataUproval`,
  updateProfile: `${url}/api/v1/admin/toUprovedOrReject`,
  changeAll: `${url}/api/v1/admin/multiSelectToUprovedOrReject`,
  addUserData: `${url}/api/v1/automation/addUserData`,
  signUpBountyArray: `${url}/api/v1/automation/signUpBountyArray`,
  getTransaction: `${url}/api/v1/automation/getTransaction`,
  addAttandance: `${url}/api/v1/automation/addAttandance`,
  signUpBountyWithAttandance: `${url}/api/v1/automation/signUpBountyWithAttandance`,
  getSelfAssessment: `${url}/api/v1/admin/getSelfAssessment`,
  forgotPassword: `${url}/api/v1/admin/forgotPassword`,
  // otpVerify: `${url}/api/v1/admin/otpVerify`,
  changePassword: `${url}/api/v1/admin/changePassword`,
  getUserData: `${url}/api/v1/admin/getUserData`,
  getUserDataBlockChain: `${url}/api/v1/admin/getUserDataBlockChain`,
  getTransactionBlockChain: `${url}/api/v1/admin/getTransactionBlockChain`,
  getSelfAssessmentBlockChain: `${url}/api/v1/admin/getSelfAssessmentBlockChain`,
  getAttendanceBlockChain: `${url}/api/v1/admin/getAttendanceBlockChain`,
  getUserBadgeBlockChain: `${url}/api/v1/admin/getUserBadgeBlockChain`,
  mailSentInArray: `${url}/api/v1/admin/mailSentInArray`,
  resumeList: `${url}/api/v1/admin/resumeList`,
  resumeApproval: `${url}/api/v1/admin/resumeApproval`,
  viewFile: `${url}/api/v1/admin/viewFile`,
  createUserBadge: `${url}/api/v1/admin/createUserBadge`,
  createUserBadgeOtherArray: `${url}/api/v1/admin/createUserBadgeOtherArray`,

  employeeeList: `${url}/api/v1/admin/employeeeList`,
  employeeOtp: `${url}/api/v1/admin/employeeOtp`,
  employeeSignUp: `${url}/api/v1/admin/employeeSignUp`,
  employeeApproval: `${url}/api/v1/admin/employeeApproval`,
  employeeeBufferList: `${url}/api/v1/admin/employeeeBufferList`,
  import_excel: `${challenges}/import_excel/`,
  resumeAnalysis: `${resume}/resume_upload/up`,
  resumeAnalysisAWS: `${resume}/resume_upload/aws`,
  resumeAnalysisSearch: `${resume}/resume_upload/up_search`,
  resumeAnalysisAWSSearch: `${resume}/resume_upload/aws_search`,
  createUserBadgeArray: `${url}/api/v1/admin/createUserBadgeArray`,
  createCourse: `${url}/api/v1/course/createCourse`,
  listDomain: `${url}/api/v1/setting/listDomain`,
  listCourse: `${url}/api/v1/course/listCourse`,
  uploadIpfs: `${url}/api/v1/course/uploadIpfs`,
  uploadNFT: `${url}/api/v1/course/uploadNFT`,
  deleteCourse: `${url}/api/v1/course/deleteCourse`,
  getCourse: `${url}/api/v1/course/getCourse`,
  editCourse: `${url}/api/v1/course/editCourse`,
  addFaculty: `${url}/api/v1/admin/addFaculty`,
  facultyList: `${url}/api/v1/admin/facultyList`,
  getFaculty: `${url}/api/v1/admin/getFaculty`,
  createDegree: `${url}/api/v1/admin/createDegree`,
  listDegree: `${url}/api/v1/admin/listDegree`,
  deleteDegree: `${url}/api/v1/admin/deleteDegree`,
  updateDegree: `${url}/api/v1/admin/updateDegree`,
  dashboard: `${url}/api/v1/admin/dashboard`,

  updateFaculty: `${url}/api/v1/admin/updateFaculty`,
  deleteFaculty: `${url}/api/v1/admin/deleteFaculty`,
  createDomain: `${url}/api/v1/setting/createDomain`,
  deleteDomain: `${url}/api/v1/setting/deleteDomain`,
  getDomainByDegreeId: `${url}/api/v1/setting/getDomainByDegreeId`,

  editDomain: `${url}/api/v1/setting/editDomain`,
  getDomain: `${url}/api/v1/setting/getDomain`,
  facultyCourseList: `${url}/api/v1/course/facultyCourseList`,
  createSubject: `${url}/api/v1/course/createSubject`,
  listSubject: `${url}/api/v1/course/listSubject`,
  deleteSubject: `${url}/api/v1/course/deleteSubject`,
  viewSubject: `${url}/api/v1/course/viewSubject`,
  updateSubject: `${url}/api/v1/course/updateSubject`,
  listSubjectByDomainId: `${url}/api/v1/course/listSubjectByDomainId`,

  addTechnology: `${url}/api/v1/technology/addTechnology`,
  listTechnology: `${url}/api/v1/technology/listTechnology`,
  viewTechnology: `${url}/api/v1/technology/viewTechnology`,
  updateTechnology: `${url}/api/v1/technology/updateTechnology`,
  deleteTechnology: `${url}/api/v1/technology/deleteTechnology`,

  // course plan

  //university
  addUniversity: `${url}/api/v1/university/addUniversity`,
  editUniversity: `${url}/api/v1/university/editUniversity`,
  getUniversity: `${url}/api/v1/university/getUniversity`,
  listUniversity: `${url}/api/v1/university/listUniversity`,
  deleteUniversity: `${url}/api/v1/university/deleteUniversity`,
  addEnterPrise: `${url}/api/v1/enterprise/addEnterPrise`,
  listEnterprise: `${url}/api/v1/enterprise/listEnterprise`,
  deleteEnterprise: `${url}/api/v1/enterprise/deleteEnterprise`,
  getEnterprise: `${url}/api/v1/enterprise/getEnterprise`,
  editEnterprise: `${url}/api/v1/enterprise/editEnterprise`,
  addPrice: `${url}/api/v1/price/addPrice`,
  priceList: `${url}/api/v1/price/priceList`,
  priceDelete: `${url}/api/v1/price/priceDelete`,
  priceUpdate: `${url}/api/v1/price/priceUpdate`,
  priceView: `${url}/api/v1/price/priceView`,
  userList: `${url}/api/v1/admin/userList`,
  userListWithoutPage: `${url}/api/v1/admin/userListWithoutPage`,

  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  userTransactionList: `${url}/api/v1/admin/userTransactionList`,
  marketplaceCoursePurchaseList: `${url}/api/v1/admin/marketplaceCoursePurchaseList`,
  addSkills: `${url}/api/v1/skill/addSkills`,
  skillsList: `${url}/api/v1/skill/skillsList`,
  addSubSkills: `${url}/api/v1/skill/addSubSkills`,
  viewSkills: `${url}/api/v1/skill/viewSkills`,
  viewSubSkills: `${url}/api/v1/skill/viewSubSkills`,
  deleteSkills: `${url}/api/v1/skill/deleteSkills`,
  deleteSubSkills: `${url}/api/v1/skill/deleteSubSkills`,
  editSkills: `${url}/api/v1/skill/editSkills`,
  subskillsList: `${url}/api/v1/skill/subskillsList`,
  editSubSkills: `${url}/api/v1/skill/editSubSkills`,
  addSubAdmin: `${url}/api/v1/admin/addSubAdmin`,
  subadminList: `${url}/api/v1/admin/subadminList`,
  viewSubAdmin: `${url}/api/v1/admin/viewSubAdmin`,
  updateSubAdmin: `${url}/api/v1/admin/updateSubAdmin`,
  blockUnblockSubAdmin: `${url}/api/v1/admin/blockUnblockSubAdmin`,
  transactionList: `${url}/api/v1/admin/transactionList`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  getLogs: `${url}/api/v1/admin/getLogs`,
  userBadgeList: `${url}/api/v1/admin/userBadgeList`,
  // profile: `${url}/api/v1/admin/profile`,
  listCertificate: `${url}/api/v1/certificate/listCertificate`,

  certificateView: `${url}/api/v1/certificate/certificateView/`,
  acceptReject: `${url}/api/v1/certificate/acceptReject`,
  // ticket query
  ticketList: `${url}/api/v1/ticket/ticketList`,
  updateTicketStatus: `${url}/api/v1/ticket/updateTicketStatus`,
  replyToTicket:`${url}/api/v1/admin/replyToTicket`,
  fetchRepliesByTicketId: `${url}/api/v1/ticket/fetchRepliesByTicketId`,
  // Notification
  addAnnouncement: `${url}/api/v1/notification/addAnnouncement`,
  listAllUserForNotificaton: `${url}/api/v1/notification/listAllUserForNotificaton`,
  listAnnouncement: `${url}/api/v1/notification/listAnnouncement`,
  viewAnnouncement: `${url}/api/v1/notification/viewAnnouncement`,

  //Courses
  getAllCategory: `${url}/api/v1/course/getAllCategory`,
  createCategory: `${url}/api/v1/course/createCategory`,
  updateCategory: `${url}/api/v1/course/updateCategory`,
  deleteCategory: `${url}/api/v1/course/deleteCategory`,
  createSubCategory: `${url}/api/v1/course/createSubCategory`,
  getAllSubCategory: `${url}/api/v1/course/getAllSubCategory`,
  updateSubCategory: `${url}/api/v1/course/updateSubCategory`,
  deleteSubCategory: `${url}/api/v1/course/deleteSubCategory`,
  getAllFreeCourse: `${url}/api/v1/user/getAllFreeCourse`,

  getTemplateBySubCategory: `${url}/api/v1/course/getTemplateBySubCategory`,
  createTemplate: `${url}/api/v1/course/createTemplate`,
  deleteTemplate: `${url}/api/v1/course/deleteTemplate`,
  skillsList: `${url}/api/v1/skill/skillsList`,
  subskillsList: `${url}/api/v1/skill/subskillsList`,
  getTemplateData: `${url}/api/v1/course/getTemplateData`,
  filterCourse: `${url}/api/v1/course/filterCourse`,

  // courseseo
  addCourseSEO: `${url}/api/v1/courseseo/addCourseSEO`,
  editCourseSEO: `${url}/api/v1/courseseo/editCourseSEO`,
  deleteCourseSEO: `${url}/api/v1/courseseo/deleteCourseSEO`,
  viewCourseSEO: `${url}/api/v1/courseseo/viewCourseSEO`,
  courseSEOList: `${url}/api/v1/courseseo/courseSEOList`,

  //categorySeo

  addCategorySEO: `${url}/api/v1/categoryseo/addCategorySEO`,
  editCategorySEO: `${url}/api/v1/categoryseo/editCategorySEO`,
  deleteCategorySEO: `${url}/api/v1/categoryseo/deleteCategorySEO`,
  viewCategorySEO: `${url}/api/v1/categoryseo/viewCategorySEO`,
  categorySEOList: `${url}/api/v1/categoryseo/categorySEOList`,

  // subcategorySeo

  addSubCategorySEO: `${url}/api/v1/subcategoryseo/addSubCategorySEO`,
  editSubCategorySEO: `${url}/api/v1/subcategoryseo/editSubCategorySEO`,
  deleteSubCategorySEO: `${url}/api/v1/subcategoryseo/deleteSubCategorySEO`,
  viewSubCategorySEO: `${url}/api/v1/subcategoryseo/viewSubCategorySEO`,
  subCategorySEOList: `${url}/api/v1/subcategoryseo/subCategorySEOList`,

  createReferalCode: `${url}/api/v1/referalcode/createReferalCode`,
  referalCodeList: `${url}/api/v1/referalcode/referalCodeList`,
  deleteReferalCode: `${url}/api/v1/referalcode/deleteReferalCode`,
  viewReferalCode: `${url}/api/v1/referalcode/viewReferalCode`,
  editReferalCode: `${url}/api/v1/referalcode/editReferalCode`,

  addInstructor: `${url}/api/v1/instructor/addInstructor`,
  editInstructor: `${url}/api/v1/instructor/editInstructor`,
  blockUnblockInstructor: `${url}/api/v1/instructor/blockUnblockInstructor`,
  viewInstructor: `${url}/api/v1/instructor/viewInstructor`,
  instructorList: `${url}/api/v1/instructor/instructorList`,

  addCourseModule: `${url}/api/v1/coursemodule/addCourseModule`,
  editCourseModule: `${url}/api/v1/coursemodule/editCourseModule`,
  deleteCourseModule: `${url}/api/v1/coursemodule/deleteCourseModule`,
  courseModuleList: `${url}/api/v1/coursemodule/courseModuleList`,
  viewCourseModule: `${url}/api/v1/coursemodule/viewCourseModule`,
};
export default ApiConfig;
