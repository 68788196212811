import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaBarcode, FaSquarespace, FaUserAlt } from "react-icons/fa";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import PropTypes from "prop-types";
import { MdCardMembership } from "react-icons/md";

import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import {
  // AiFillDashboard,
  AiFillHome,
  AiOutlineDashboard,
  BiWalletAlt,
  BsFillPersonCheckFill,
  // BsFillPersonPlusFill,
  GiReceiveMoney,
  RiSettingsLine,
} from "react-icons";
import { AiFillDashboard } from "react-icons/ai";
import { BsFillPersonPlusFill } from "react-icons/bs";

import NavItem from "./NavItem";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import { TfiAnnouncement } from "react-icons/tfi";
import { FaBlog } from "react-icons/fa";

import { MdDescription, MdHistory, MdCategory } from "react-icons/md";
import { RiExchangeDollarFill, RiWallet3Line } from "react-icons/ri";
import SearchIcon from "@material-ui/icons/Search";
import { MdSubscriptions } from "react-icons/md";
import { MdOutlineWork } from "react-icons/md";

import { AuthContext } from "src/context/Auth";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CardMembershipTwoToneIcon from '@material-ui/icons/CardMembershipTwoTone';
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import RedeemIcon from '@material-ui/icons/Redeem';
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items &&
        items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
          []
        )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title && item.title + depth;
  if (item.items && item.check) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        check={item?.check && item?.check}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item?.items && item?.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item?.href && item?.href}
        icon={item?.icon && item?.icon}
        info={item?.info && item?.info}
        check={item?.check && item?.check}
        key={key}
        title={item?.title && item?.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,

   
  },
  desktopDrawer: {
    top: "76px",
    width: "270px",
    height: "calc(100% - 115px)",
    margin: "5px 10px 10px 15px",
    background: "#fff",
    boxShadow: "0 0 40px 0 rgba(94,92,154, .06)",
    marginTop: "21px",
    marginLeft: "13px",
    borderRadius: "none",
    borderRight: "none",
    
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "19px",
    left: "16px",
    background: "transparent",
    fontWeight: "700",
    fontSize: "13px",
    color: "#3e3f5e",
    width:"90%"
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  footerBox: {
    width: "100%",
    height: "80px",
    backgroundColor: "#fff",
    position: "absolute",
    bottom: "-4px",
    overflow: "hidden",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const userType = auth?.userData?.userType;

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const sections = [
    {
      items: [
        {
          title: "Dashboard",
          icon: AiFillDashboard,
          href: "/dashboard",
          check: auth?.userData?.permissions?.dashboard,
        },
        {
          title: "Users List",
          icon: BsFillPersonPlusFill,
          href: "/user-list",
          check: auth?.userData?.permissions?.userManagement,
        },
        {
          title: "Users Wallet",
          icon: BsFillPersonPlusFill,
          href: "/wallets-list",
          check: auth?.userData?.permissions?.userManagement,
        },
        {
          title: "Wallet Balance",
          icon: BsFillPersonPlusFill,
          href: "/wallets-balance",
          check: auth?.userData?.permissions?.userManagement,
        },
        {
          title: "Gc Rewards",
          icon: RedeemIcon,
          href: "/transaction-list",
          check: auth?.userData?.permissions?.userManagement,
        },
      
        {
          title: "Create Badge",
          icon: LoyaltyIcon,
          href: "/create-badges",
          check: auth?.userData?.permissions?.badges,
        },
        {
          title: "Badges List",
          icon: LoyaltyIcon,
          href: "/badges-list",
          check: auth?.userData?.permissions?.badges,
        },
        {
          title: "Membership Plans",
          icon: MdCardMembership,
          href: "/member-ship",
          check:  auth?.userData?.permissions?.membershipPlan,
        },
        // {
        //   title: "Facuilty",
        //   icon: RiWallet3Line,
        //   href: "/faculity-list",
        //   check: auth?.userData?.permissions?.faculty,

        // },
        {
          title: "Category List",
          icon: MdCategory,
          href: "/course-category",
          check: auth?.userData?.permissions?.category,
        },
        // {
        //   title: "Course List",
        //   icon: MdHistory,
        //   href: "/course-list",
        //   check: auth?.userData?.permissions?.courses,
        // },
        {
          title: "Skills Marketplace",
          icon: MdHistory,
          href: "/marketplace",
          check: auth?.userData?.permissions?.courses,
        },
        {
          title: "Marketplace List",
          icon: MdHistory,
          href: "/marketplace-list",
          check: auth?.userData?.permissions?.marketplaceList,
        },
        {
          title: "Skill Drops",
          icon: MdHistory,
          href: "/drop",
          check: auth?.userData?.permissions?.dropCourses,
        },

        {
          title: "Skill Drops List",
          icon: MdHistory,
          href: "/skill-drops-list",
          check: auth?.userData?.permissions?.skillDropsList,
        },

        {
          title: "Add Module",
          icon: SupervisorAccountIcon,
          href: "/course-module",
          check:  auth?.userData?.permissions?.courseModule,
        },
        // {
        //   title: "Instructor List",
        //   icon: SupervisorAccountIcon,
        //   href: "/instructor-list",
        //   check: auth?.userData?.permissions?.Request,
        // },
        // {
        //   title: "Drop",
        //   icon: ArrowDownwardIcon,
        //   href: "/drop-list",
        //   check: auth?.userData?.permissions?.dropCourses,
        // },
        // {
        //   title: "Transaction Management",
        //   icon: MdHistory,
        //   href: "/transaction-list",
        // },
        // {
        //   title: "Challenges",
        //   icon: RiSettingsLine,
        //   href: "/challenges",
        //   check: auth?.userData?.permissions?.challenges,

        // },
        // {
        //   title: "Request",
        //   icon: RiSettingsLine,
        //   href: "/admin",
        //   check: auth?.userData?.permissions?.Request,

        // },
        // {
        //   title: "Skill Request",
        //   icon: CardMembershipIcon,
        //   href: "/certificate-list",
        //   check: auth?.userData?.permissions?.skillsRequest,

        // },
        {
          title: "Tier Plan Subscribers",
          icon: CardMembershipTwoToneIcon,
          href: "/tier-plan-subscribers",
          check:  auth?.userData?.permissions?.tierPlanSubscribe,
        },
    
        {
          title: "Subadmin List",
          icon: SupervisorAccountIcon,
          href: "/subadmin-list",
          check: auth?.userData?.permissions?.Request,
        },
        // {
        //   title: "Course UserList",
        //   icon: SearchIcon,
        //   href: "/course-userList",
        // },
        {
          title: "Ticket Query Request List",
          icon: QueryBuilderIcon,
          href: "/query-list",
          check: auth?.userData?.permissions?.queryrequest,
        },
        {
          title: "Notifications",
          icon: TfiAnnouncement,
          href: "/notifications",
          check:  auth?.userData?.permissions?.announcement,
        },
        {
          title: "Newsletter Subscribers",
          icon: MdSubscriptions,
          href: "/subscribe-user",
          check:  auth?.userData?.permissions?.newsletterSubscribers,
        },

        // {
        //   title: "Profile",
        //   icon: RiSettingsLine,
        //   href: "/setting",
        // },
        // {
        //   title: "Course SEO",
        //   icon: SearchIcon,
        //   href: "/course-seo",
        //   check:  auth?.userData?.permissions?.courseSEO,
        // },
        {
          title: "CoE Partners",
          icon: SupervisorAccountIcon,
          href: "/coe-partners",
          check:  auth?.userData?.permissions?.COEPartners,
        },
        {
          title: "Create Blogs",
          icon: FaBlog,
          href: "/blog",
          check:  auth?.userData?.permissions?.createBlogs,
        },
        // {
        //   title: "Create Job & Career",
        //   icon: MdOutlineWork,
        //   href: "/job-career",
        //   check: true,
        // },
        {
          title: "Upload Static Content",
          icon: FaBlog,
          href: "/static",
          check:  auth?.userData?.permissions?.staticContentUpload,
        },
      ],
    },
  ];
  const content = (
    <Box
      height="100%"
      
      display="flex"
      flexDirection="column"
      pt={1} 
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox" style={{ scrollbarWidth: "thin !important" }}>
            {sections.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section && section.subheader && section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  img: section && section?.img,
                  items:
                    section &&
                    section?.items &&
                    section?.items.map((item) => ({
                      ...item,
                      check: userType === "SUB_ADMIN" ? item.check : true,
                    })),
                  pathname: location?.pathname && location?.pathname,
                })}
              </List>
            ))}
          </Box>
        </Box>
        <Box className={classes.footerBox}>
          <Button
            onClick={() => setIsLogout(true)}
            className={classes.logoutButton}
          >
            <ExitToAppIcon
              style={{
                marginRight: "16px",
              }}
            />
            &nbsp; Logout
          </Button>
        </Box>
        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Typography variant="h6" color="primary">
                  Are you sure want to logout?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2}>
                <Button
                  className="modelbtn"
                  color="primary"
                  variant="contained"
                  style={{ padding: "8px 21px" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  style={{
                    padding: "8px 21px",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    history.push("/");
                    sessionStorage.clear();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
             {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
